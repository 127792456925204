<template>
  <div style="max-width: 960px; min-height: 420px" class="max-w-fit">
    <div v-if="showStartTrial" class="p-20 text-center">
      <h3 :class="['text-' + type, 'text-4xl']">
        {{ $t('Start your trial now') }}
      </h3>
      <p>
        {{
          $t(
            'Start your trial to experience the full features of Transkribus scholar. After your trial phase, you can keep using Transkribus for free or upgrade to a Scholar plan. Your trial will start right now and will end on'
          )
        }}
      </p>
      <p class="font-bold text-lg">
        <BaseDate :value="getExpirationDate()" />
      </p>
      <div class="flex gap-4 mt-8">
        <BaseButton
          class="grow"
          :type="'secondary'"
          :label="$t('Cancel')"
          @click="$emit('close')"
        ></BaseButton>
        <BaseButton
          class="grow"
          :type="type"
          :label="$t('Start trial')"
          @click="checkTrial()"
        ></BaseButton>
      </div>
    </div>
    <div class="min-h-[420px]" v-else>
      <div class="min-h-[420px] flex justify-between">
        <div class="w-1/2 px-8 py-8 flex flex-col justify-between">
          <div>
            <p class="mb-0 text-sm">{{ $t(subheading) }}</p>

            <h3
              :class="['text-' + type, 'text-3xl', 'leading-9', 'pt-4']"
              v-html="$t(heading)"
            ></h3>
            <p class="py-5 text-sm">{{ $t(description) }}</p>
            <div v-if="features?.length > 0">
              <div
                :class="['text-' + type]"
                v-for="(feature, index) in features"
                :key="index"
              >
                <i class="mdi mdi-arrow-up-bold-circle"></i> {{ $t(feature) }}
              </div>
            </div>
          </div>
          <!-- class="grid grid-cols-2 gap-4 pt-auto  content-end " -->
          <div>
            <div class="flex gap-4 pt-8 pb-0 content-end">
              <BaseButton
                v-if="cta1"
                class="grow"
                :type="type"
                :label="cta1.label"
                @click="openUrl(cta1.link)"
              ></BaseButton>
              <!-- Start trial if not used else Upgrade now -->
              <BaseButton
                v-if="!cta1 && !oneCTA"
                class="grow"
                :type="type"
                :label="
                  subscription?.usedTrial
                    ? $t('Upgrade now')
                    : $t('Start trial')
                "
                @click="trialOrUprade()"
              ></BaseButton>
              <BaseButton
                v-if="!oneCTA"
                class="grow"
                :icon="'open-in-new'"
                iconRight
                :type="'secondary'"
                :label="cta2.label"
                @click="openUrl(cta2.link)"
              ></BaseButton>
              <BaseButton
                v-if="oneCTA"
                class="grow"
                :type="oneCTA.type"
                :label="oneCTA.label"
                @click="
                  oneCTA.link ? openUrl(oneCTA.link) : ctaAction(oneCTA.action)
                "
              ></BaseButton>
            </div>
          </div>
        </div>
        <div
          :class="[
            { 'bg-upgrade-50': type === 'upgrade' },
            { 'bg-gradient-info': type === 'primary' },
            'w-1/2',
            'px-8',
            'py-8',
            'flex',
            'items-center',
          ]"
        >
          <img :src="`/images/${image}.svg`" style="max-height: 420px" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Premium',
  setup() {
    const subscriptionApi = useSubscriptions()
    const { keycloak, userProfile } = useKeycloak()
    return {
      subscriptionApi,
      keycloak,
      userProfile,
    }
  },
  props: {
    heading: {
      type: String,
      default: 'Heading',
    },
    subheading: {
      type: String,
      default: 'Sub Heading',
    },
    description: {
      type: String,
      default:
        'Description text which shortly explains the current plan and the advantages ',
    },
    features: {
      type: Array,
      default: [],
    },
    image: {
      type: String,
      default: 'premium-placeholder',
    },
    isSites: {
      type: Boolean,
      default: false,
    },
    cta1: {
      type: {
        label: String,
        link: String,
      },
    },
    cta2: {
      type: {
        label: String,
        link: String,
      },
      default: {
        label: 'Learn more',
        link: 'https://transkribus.org/plans',
      },
    },
    oneCTA: {
      type: Object, // {label: 'string', link: 'string', type: 'string'}
      default: null,
    },
    type: {
      type: String,
      default: 'upgrade',
    },
  },
  computed: {
    userId() {
      return this.userProfile?.UserId
    },
  },
  data() {
    return {
      isLoading: false,
      subscription: null,
      showStartTrial: false,
    }
  },
  async mounted() {
    await this.fetchSubscription()
  },
  methods: {
    trialOrUprade() {
      if (this.subscription?.usedTrial) {
        this.$emit('upgrade')
        this.$bus.emit('upgrade')
        this.openUrl('https://transkribus.org/scholar-upgrade')
      } else {
        this.showStartTrial = true
      }
    },
    async checkTrial() {
      this.$emit('trialStart')
      this.$bus.emit('trialStart')
      if (!this.$features.posthogTesting) {
        this.isLoading = true
        let response
        try {
          response = await this.subscriptionApi.startTrial({
            userId: this.userId,
          })
          // Force a new token after starting the trial
          await this.getNewKeycloakToken()
          this.$bus.emit('notification', {
            message: this.$t('Trial started successfully'),
            type: 'success',
          })
          this.$bus.emit('close-modal')
          reloadNuxtApp()
        } catch (err) {
          this.$bus.emit('notification', {
            message: this.$t('Trial start failed'),
            type: 'error',
          })
          this.$sentry.captureException(err)
        }
        this.isLoading = false
      } else {
        this.$bus.emit('close-modal')
      }
    },
    getExpirationDate() {
      const date = new Date()
      date.setDate(date.getDate() + 30)
      return date.toDateString()
    },
    async getNewKeycloakToken() {
      try {
        await this.keycloak.loadUserProfile()
        await this.keycloak.updateToken(-1)
      } catch (error) {
        this.$sentry.captureException(error)
      }
    },
    async fetchSubscription() {
      const { plan, organization, subscription } =
        await this.subscriptionApi.getSubscription({ userId: this.userId })
      if (subscription) {
        this.subscription = subscription
      }
    },

    openUrl(url) {
      window.open(url, '_blank')
      this.$bus.emit('close-modal')
    },

    ctaAction(action) {
      if (typeof action === 'string' && action === 'upgradePlan') {
        this.trialOrUprade()
      }
    },
  },
}
</script>
<style>
.modal {
  --tw-bg-opacity: 0.56;
}
.modal-box {
  border-radius: 4px;
}
</style>
